var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      attrs: { title: "编辑", show: _vm.open, isForm: _vm.isForm },
      on: {
        show: _vm.show,
        closed: _vm.closed,
        save: _vm.save,
        edit: _vm.edit,
      },
    },
    [
      _c(
        "div",
        {},
        [
          _c("info-row", { attrs: { label: "名称" } }, [
            _vm._v(_vm._s(_vm.curRow.platformName)),
          ]),
          _c("info-row", { attrs: { label: "序号" } }, [
            _vm._v(_vm._s(_vm.curRow.sequenceNo)),
          ]),
          _c("info-row", { attrs: { label: "统筹" } }, [
            _c(
              "div",
              { staticClass: "flex flex-wrap" },
              _vm._l(_vm.curRow.adminUsers, function (item) {
                return _c("span", { key: item.userId }, [
                  _vm._v(_vm._s(item.userName)),
                ])
              }),
              0
            ),
          ]),
          _c("info-row", { attrs: { label: "辅助管理员" } }, [
            _c(
              "div",
              { staticClass: "flex flex-wrap" },
              _vm._l(_vm.curRow.assistantUsers, function (item) {
                return _c("span", { key: item.userId }, [
                  _vm._v(_vm._s(item.userName)),
                ])
              }),
              0
            ),
          ]),
          _c("info-row", { attrs: { label: "所属部门" } }, [
            _vm._v(
              _vm._s(
                _vm.curRow.department == null
                  ? "无"
                  : _vm.curRow.department.departName
              )
            ),
          ]),
          _c("info-row", { attrs: { label: "规范状态" } }, [
            _vm._v(_vm._s(_vm._f("finished")(_vm.curRow.guideStatus))),
          ]),
          _c("info-row", { attrs: { label: "规范状态" } }, [
            _vm._v(_vm._s(_vm._f("finished")(_vm.curRow.ruleStatus))),
          ]),
          _c("info-row", { attrs: { label: "术语表状态" } }, [
            _vm._v(_vm._s(_vm._f("finished")(_vm.curRow.termsStatus))),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "form" }, slot: "form" },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "dataForm",
                attrs: {
                  layout: "horizontal",
                  model: _vm.formData,
                  rules: _vm.rules,
                },
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "名称", prop: "platformName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入平台名称" },
                    model: {
                      value: _vm.formData.platformName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "platformName", $$v)
                      },
                      expression: "formData.platformName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "序号", prop: "sequenceNo" } },
                [
                  _c("a-input-number", {
                    model: {
                      value: _vm.formData.sequenceNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "sequenceNo", $$v)
                      },
                      expression: "formData.sequenceNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "选择统筹", prop: "adminUsers" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        mode: "multiple",
                        placeholder: "请选择统筹人员",
                      },
                      on: { change: _vm.changeAdminUser },
                      model: {
                        value: _vm.selcetAdminUser,
                        callback: function ($$v) {
                          _vm.selcetAdminUser = $$v
                        },
                        expression: "selcetAdminUser",
                      },
                    },
                    _vm._l(_vm.userList, function (i) {
                      return _c("a-select-option", { key: i.id }, [
                        _vm._v(
                          "\n            " + _vm._s(i.realname) + "\n          "
                        ),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "选择助手", prop: "assistantUsers" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        mode: "multiple",
                        placeholder: "请选择助手用户",
                      },
                      on: { change: _vm.changeAssistantUser },
                      model: {
                        value: _vm.selcetAssUser,
                        callback: function ($$v) {
                          _vm.selcetAssUser = $$v
                        },
                        expression: "selcetAssUser",
                      },
                    },
                    _vm._l(_vm.userList, function (i) {
                      return _c("a-select-option", { key: i.id }, [
                        _vm._v(
                          "\n            " + _vm._s(i.realname) + "\n          "
                        ),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "选择部门", prop: "selectedDepart" } },
                [
                  _c("a-tree-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      treeDefaultExpandAll: "",
                      "tree-data": _vm.departList,
                      "search-placeholder": "选择部门",
                    },
                    on: { change: _vm.changeDepart },
                    model: {
                      value: _vm.selectedDepart,
                      callback: function ($$v) {
                        _vm.selectedDepart = $$v
                      },
                      expression: "selectedDepart",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }