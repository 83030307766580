<template>
  <drawer-layout title="编辑" :show="open" :isForm="isForm" @show="show" @closed="closed" @save="save" @edit="edit">
    <div class="">
      <info-row label="名称">{{ curRow.platformName }}</info-row>
      <info-row label="序号">{{ curRow.sequenceNo }}</info-row>
      <info-row label="统筹">
        <div class="flex flex-wrap">
          <span v-for="item in curRow.adminUsers" :key="item.userId">{{ item.userName }}</span>
        </div>
      </info-row>
      <info-row label="辅助管理员">
        <div class="flex flex-wrap">
          <span v-for="item in curRow.assistantUsers" :key="item.userId">{{ item.userName }}</span>
        </div>
      </info-row>
      <info-row label="所属部门">{{ curRow.department == null ? '无' : curRow.department.departName }}</info-row>
      <info-row label="规范状态">{{ curRow.guideStatus | finished }}</info-row>
      <info-row label="规范状态">{{ curRow.ruleStatus | finished }}</info-row>
      <info-row label="术语表状态">{{ curRow.termsStatus | finished }}</info-row>
    </div>
    <div slot="form">
      <a-form-model ref="dataForm" layout="horizontal" :model="formData" :rules="rules" v-bind="layout">
        <a-form-model-item label="名称" prop="platformName">
          <a-input v-model="formData.platformName" placeholder="请输入平台名称"></a-input>
        </a-form-model-item>
        <a-form-model-item label="序号" prop="sequenceNo">
          <a-input-number v-model="formData.sequenceNo"></a-input-number>
        </a-form-model-item>
        <a-form-model-item label="选择统筹" prop="adminUsers">
          <a-select mode="multiple" placeholder="请选择统筹人员" v-model="selcetAdminUser" @change="changeAdminUser">
            <a-select-option v-for="i in userList" :key="i.id">
              {{ i.realname }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="选择助手" prop="assistantUsers">
          <a-select mode="multiple" placeholder="请选择助手用户" v-model="selcetAssUser" @change="changeAssistantUser">
            <a-select-option v-for="i in userList" :key="i.id">
              {{ i.realname }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="选择部门" prop="selectedDepart">
          <a-tree-select
          treeDefaultExpandAll
            v-model="selectedDepart"
            style="width: 100%"
            :tree-data="departList"
            search-placeholder="选择部门"
            @change="changeDepart"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </drawer-layout>
</template>

<script>
import { DrawerMixins } from '@/mixins/DrawerMixins'
export default {
  mixins: [DrawerMixins],

  data() {
    return {
      selcetAdminUser: [],
      selcetAssUser: [],
      rules: {
        platformName: [{ required: true, message: '请输入平台名称', trigger: 'blur' }],
      },
      url: {
        edit: '/platform/edit',
        add: '/platform/add',
      },
    }
  },
  methods: {
    initForm() {
      this.formData = {
        adminUsers: [],
        assistantUsers: [],
        department: {},
        guideStatus: true,
        platformId: 0,
        platformName: '',
        ruleStatus: true,
        sequenceNo: 0,
        termsStatus: true,
      }
      this.selectedUsers = []
      this.selectedDepart = ''
      this.assistantUsers = []
      this.selcetedAssistantUsers = []
    },
    show() {
      console.log('显示...')
      this.getUserList()
      this.getDepartList()
      this.getPosition()
      this.isEdit = this.isEditForm
      if (this.isEdit) {
        console.log(this.curRow)
        this.formData = this.curRow
        const { assistantUsers, adminUsers, department } = this.formData
        this.selcetAdminUser = adminUsers.map((item) => item.userId)
        this.selcetAssUser = assistantUsers.map((item) => item.userId)
        this.selectedDepart = department == null ? '' : department.departName
      }
    },

    changeAdminUser(val) {
      console.log(val)
      this.formData.adminUsers = val.map((item) => {
        return { userId: item }
      })
    },
    changeAssistantUser(val) {
      this.formData.assistantUsers = val.map((item) => {
        return { userId: item }
      })
    },

    setFormData() {
      const { assistantUsers, adminUsers, department } = this.curRow
    },
  },
  created() {
    this.initForm()
  },
}
</script>

<style lang='stylus'></style>